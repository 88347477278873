<template>
  <div data-app>
    <div class="row">
      <div class="col-md-12">
        <b-navbar toggleable="lg" type="dark">
          <template v-if="isBusy">
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else>
            <b-row show class="col-md-12">
              <b-col cols="12" md="2" sm="12">
                <router-link
                  :to="{
                    name: 'uploadedAssignment'
                  }"
                  class="btn btn-primary mb-3"
                >
                  <i class="flaticon2-right-arrow"></i>
                  {{ $t("GENERAL.BACK") }}
                </router-link>
              </b-col>
              <b-col cols="12" md="5" sm="12">
                <b-alert variant="info" show
                  >{{ $t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE") }}: &nbsp;
                  {{ assignment.title }}</b-alert
                >
              </b-col>
              <b-col cols="12" md="5" sm="12">
                <b-alert variant="warning" show
                  >{{ $t("ASSIGNMENT.DUE_DATE") }}: &nbsp;{{
                    assignment.due_date
                  }}</b-alert
                >
              </b-col>
            </b-row>
          </template>
        </b-navbar>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <KTPortlet :title="title" class="kt-section__title-lg">
          <template v-slot:body>
            <div v-show="!fileView.state">
              <b-overlay :show="fileView.loading" rounded="sm">
                <div
                  role="alert"
                  v-if="errors.length"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text">
                    <ul v-for="(error, i) in errors" :key="i">
                      <li>{{ error }}</li>
                    </ul>
                  </div>
                </div>
                <div role="alert" v-if="message" class="alert alert-warning">
                  <div class="alert-text">
                    {{ message }}
                  </div>
                </div>
                <b-row v-else>
                  <b-table
                    :items="submitted_assignments_list"
                    :fields="items_fields"
                    :busy="isBusy"
                    striped
                    bordered
                    head-variant="dark"
                    show-empty
                  >
                    <template v-slot:empty="scope">
                      <p class="text-center">
                        {{ $t("ATTENDANCE.NO_STUDENT") }}
                      </p>
                    </template>
                    <template v-slot:cell(date)="{ item }">
                      <p class="text-left" v-if="item.date == null">
                        {{ $t("GENERAL.NO_ATTEMPT") }}
                      </p>
                      <p class="text-left" v-else>
                        {{ item.date }}
                      </p>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                      <b-row v-if="item.date != null">
                        <b-button
                          pill
                          size="sm"
                          variant="info"
                          v-b-tooltip.hover
                          :title="$t('GENERAL.VIEW_FILE')"
                          @click="loadFile(item.type, item.id)"
                        >
                          <i
                            class="fa fa-eye"
                            style="font-size: 1.3em; margin-left: 0;"
                          ></i>
                        </b-button>
                        &nbsp;&nbsp;
                        <b-button
                          pill
                          size="sm"
                          variant="success"
                          v-b-tooltip.hover
                          :title="$t('GENERAL.DOWNLOAD')"
                          @click="downloadFile(item.type, item.id)"
                        >
                          <i
                            class="fa fa-download"
                            style="font-size: 1.3em; margin-left: 0;"
                          ></i>
                        </b-button>
                        &nbsp;&nbsp;
                        <b-button
                          pill
                          size="sm"
                          variant="dark"
                          v-b-tooltip.hover
                          :title="
                            $t('ASSIGNMENT.ASSIGNMENT_EVALUATION_GRADING')
                          "
                          @click="marksFeedback(item)"
                        >
                          <i
                            class="fa fa-tasks"
                            style="font-size: 1.3em; margin-left: 0; color: white;"
                          ></i>
                        </b-button>
                      </b-row>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                      </div>
                    </template>
                  </b-table>
                </b-row>
              </b-overlay>
            </div>
            <b-row v-if="fileView.state">
              <b-col>
                <b-button
                  pill
                  size="sm"
                  variant="info"
                  v-b-tooltip.hover
                  :title="$t('GENERAL.BACK')"
                  @click="unLoadFile"
                  class="kt-margin-b-10"
                >
                  <i
                    class="fa fa-angle-left"
                    style="font-size: 1.3em; margin-left: 0;"
                  ></i>
                  {{ $t("GENERAL.BACK") }}
                </b-button>
                <FilePreview
                  :type="fileView.type"
                  :path="fileView.url"
                  :model="fileView.model"
                  :id="fileView.id"
                />
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </div>
    </div>
    <div>
      <b-modal
        no-close-on-backdrop
        ref="marksErrorReportModal"
        centered
        :title="
          $t('UPLOADED_ASSIGNMENT.MARK_FEEDBACK_FOR', {
            student: selected_item.student_name
          })
        "
        @ok="handleOk"
        @hidden="resetModal"
      >
        <template v-slot:modal-header-close><i></i></template>
        <b-overlay :show="form.submitting" variant="transparent" rounded="sm">
          <p class="my-4">
            <b-row>
              <b-alert show variant="info">{{
                $t("UPLOADED_ASSIGNMENT.NOTE")
              }}</b-alert>
            </b-row>
            <b-row>
              <b-col>
                <div role="group">
                  <label>{{ $t("GENERAL.MARK") }}:</label>
                  <b-form-input
                    id="mark"
                    name="mark"
                    type="number"
                    min="0"
                    max="100"
                    v-model="$v.form.mark.$model"
                    :state="validateState('mark')"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("GENERAL.MARK")
                      })
                    }}
                    {{ $t("VALIDATION.BETWEEN") }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div role="group">
                  <label>{{ $t("ASSIGNMENT.TEACHER_FEEDBACK") }}:</label>
                  <b-form-textarea
                    id="feedback"
                    name="feedback"
                    type="text"
                    rows="4"
                    max-rows="6"
                    v-model="$v.form.feedback.$model"
                    :state="validateState('feedback')"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-2-live-feedback">
                    {{
                      $t("VALIDATION.REQUIRED", {
                        name: $t("ASSIGNMENT.TEACHER_FEEDBACK")
                      })
                    }}
                  </b-form-invalid-feedback>
                </div>
              </b-col>
            </b-row>
          </p>
        </b-overlay>
        <template v-slot:modal-ok>{{
          $t("UPLOADED_ASSIGNMENT.SUBMIT")
        }}</template>
        <template v-slot:modal-cancel>{{ $t("GENERAL.CANCEL") }}</template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import FilePreview from "@/views/pages/FilePreview";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";

import { validationMixin } from "vuelidate";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "AssignmentEvaluationAndGrading",
  mixins: [validationMixin],
  data() {
    return {
      title: this.$t("ASSIGNMENT.ASSIGNMENT_EVALUATION_GRADING"),
      errors: [],
      isBusy: true,
      items_fields: [
        { student_id: this.$t("PROFILE.ID") },
        { student_name: this.$t("PROFILE.NAME") },
        { father_name: this.$t("PROFILE.FATHER_NAME") },
        { date: this.$t("GENERAL.SUBMITTED_ON") },
        { mark: this.$t("GENERAL.MARK") },
        { feedback: this.$t("ASSIGNMENT.TEACHER_FEEDBACK") },
        { actions: this.$t("GENERAL.ACTIONS") }
      ],
      message: null,
      fileView: {
        loading: false,
        state: false,
        type: null,
        model: "assignment-submission",
        url: null,
        id: null
      },
      selected_item: {
        id: null,
        student_name: null
      },
      form: {
        mark: null,
        feedback: null,
        submitting: false
      },
      assignment: null,
      submitted_assignments_list: []
    };
  },
  components: {
    KTPortlet,
    FilePreview
  },
  validations: {
    form: {
      mark: {
        required,
        between: between(0, 100)
      },
      feedback: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getSubmittedAssignment(id, lecturer_class_subject_id) {
      this.isBusy = true;
      this.errors = [];
      ApiService.get(
        "lecturer/submitted-assignments/" + id + "/" + lecturer_class_subject_id
      ).then(({ data }) => {
        this.submitted_assignments_list = data.submitted_assignments_list;
        this.assignment = data.assignment;
        this.isBusy = false;
      });
    },
    loadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/load/assignment-submission/" + id)
        .then(({ data }) => {
          this.fileView.state = true;
          this.fileView.type = type;
          this.fileView.url = data;
          this.fileView.id = id;
          this.fileView.loading = false;
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    unLoadFile() {
      this.fileView.state = false;
      this.fileView.type = null;
      this.fileView.url = null;
      this.fileView.id = null;
    },
    downloadFile(type, id) {
      this.fileView.loading = true;
      ApiService.get("files/get-url/download/assignment-submission/" + id)
        .then(({ data }) => {
          this.fileView.loading = false;
          window.open(data, "_blank");
        })
        .catch(() => {
          this.fileView.loading = false;
        });
    },
    marksFeedback(record) {
      this.selected_item.id = record.id;
      this.selected_item.student_name = record.student_name;
      this.$refs["marksErrorReportModal"].show();
    },
    resetModal() {
      this.form.mark = null;
      this.form.feedback = null;
      this.$v.$reset();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let formData = {
        id: this.selected_item.id,
        mark: this.$v.form.mark.$model,
        feedback: this.$v.form.feedback.$model
      };
      this.form.submitting = true;
      ApiService.put("lecturer/assignment-mark-feedback", formData)
        .then(() => {
          this.form.submitting = false;
          this.$nextTick(() => {
            this.$refs["marksErrorReportModal"].hide();
          });
          this.getSubmittedAssignment(
            this.$route.params.id,
            this.$route.params.lecturer_class_subject_id
          );
        })
        .catch(() => {
          this.form.submitting = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("ASSIGNMENT.ASSIGNMENT_EVALUATION_GRADING"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.post("lecturer/class-information", {
      id: this.$route.params.lecturer_class_subject_id
    })
      .then(({ data }) => {
        this.title +=
          " " +
          this.$t("ASSIGNMENT.TITLE", {
            class: data.class_name,
            subject: data.subject,
            time: data.class_time,
            semester: data.semester,
            faculty: data.faculty
          });
        this.getSubmittedAssignment(
          this.$route.params.id,
          this.$route.params.lecturer_class_subject_id
        );
      })
      .catch(({ response }) => {
        if (response && (response.status === 403 || response.status === 422)) {
          this.$router.push({ name: "uploadedAssignment" });
        }
      });
  }
};
</script>

<style scoped></style>
